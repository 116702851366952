<template>
  <div
    v-if="program.loading"
    class="w-full d-flex justify-center align-center"
    style="height: 100vh"
  >
    <v-progress-circular
      indeterminate
      :size="50"
      color="primary"
    ></v-progress-circular>
  </div>

  <div v-else>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title>
        <h4 class="poppins-regular font-normal text-sm">
          <router-link to="/program" class="text-decoration-none"
            ><span class="text-primary">Programs</span></router-link
          >
          <span class="text-black"> / {{ program.data.title }}</span>
        </h4>
      </v-toolbar-title>
    </v-app-bar>

    <div>
      <div class="pb-8">
        <v-row class="justify-end mt-4 mb-8">
          <v-btn
            class="mr-4"
            outlined
            color="primary"
            large
            @click="submit"
            :loading="submitting"
            :disabled="submitting"
          >
            <span class="text-sm font-semibold">Update Program</span>
          </v-btn>
          <v-btn outlined color="primary" large @click="$router.back()">
            <span class="text-sm font-semibold">Cancel</span>
          </v-btn>
        </v-row>

        <v-row>
          <v-col cols="3">
            <thumbnail-uploader
              v-model="form.thumbnail"
              :media="program.data.thumb_nail"
              :errors="form.$getError('thumb_nail_id')"
              @input="form.$clearError('thumb_nail_id')"
            />
          </v-col>

          <v-col cols="9">
            <v-row>
              <v-col>
                <v-text-field
                  v-model.trim="form.title"
                  :error-messages="form.$getError('title')"
                  class="input__outlined--regular"
                  label="Program Title"
                  outlined
                  @input="form.$clearError('title')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="9">
                <v-select
                  v-model="form.category_id"
                  :error-messages="form.$getError('category_id')"
                  :items="programCategories"
                  class="input__outlined--regular"
                  item-text="label"
                  item-value="id"
                  label="Category"
                  outlined
                  @change="form.$clearError('category_id')"
                />
              </v-col>
              <v-col cols="3">
                <v-switch
                  class="mt-3"
                  v-model="form.is_free"
                  label="Free"
                  inset
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <file-uploader
              v-model="form.video"
              label="Introduction Video"
              :initial-preview-url="form.video?.url"
              accept="video/mp4,video/x-m4v,video/*"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div>
              <p class="text-tertiary text-base">Description</p>

              <ContentEditor
                v-model="form.content"
                :errors="form.$getError('content')"
                class="content-editor"
                @input="form.$clearError('content')"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="py-8" style="border-top: 1px solid #e6e6e9">
        <weekly-exercise-list :program-id="programId" />
      </div>
    </div>
  </div>
</template>
<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import WeeklyExerciseList from '@/components/weeklyExercise/WeeklyExerciseList.vue'
import { mapActions, mapState } from 'vuex'
import Form from '@/utils/form'
import ContentEditor from '@/components/ContentEditor'
import ThumbnailUploader from '@/components/base/ThumbnailUploader.vue'
import Api from '@/services/api'
import axios from 'axios'
import FileUploader from '@/components/base/FileUploader.vue'

export default {
  name: 'ProgramItemIndex',
  components: {
    AppBarNavIcon,
    WeeklyExerciseList,
    ContentEditor,
    ThumbnailUploader,
    FileUploader,
  },
  computed: {
    ...mapState({
      program: (state) => state.program.selectedProgram,
      programCategories: (state) => state.programCategory.categories,
    }),
    programId() {
      return parseInt(this.$route.params.id)
    },
  },
  data() {
    return {
      submitting: false,
      form: new Form({
        category_id: null,
        title: null,
        content: null,
        video_thumb_nail_id: null,
        thumb_nail_id: null,
        is_free: 1,
        video: null,
        thumbnail: null,
      }),
      thumbnailUrl: '',
    }
  },
  async mounted() {
    await this.getProgramCategories()

    const programId = this.$route.params.id

    await this.getProgramDetails(programId)

    this.resetForm()
  },
  methods: {
    ...mapActions({
      getProgramDetails: 'program/getProgramDetails',
      getProgramCategories: 'programCategory/getProgramCategories',
    }),
    resetForm() {
      this.thumbnailUrl = this.program.data.thumb_nail?.url
      this.form.title = this.program.data.title
      this.form.is_free = this.program.data.is_free
      this.form.content = this.program.data.content
      this.form.category_id = this.program.data.category?.id
      this.form.video = this.program.data.video_thumb_nail
      this.form.thumbnail = this.program.data.thumb_nail
      this.form.thumb_nail_id = this.program.data.thumb_nail?.id
      this.form.video_thumb_nail_id = this.program.data.video_thumb_nail?.id
    },
    async uploadFile(file) {
      const response = await Api.post('/s3-upload-signed-url/generate', {
        file_name: file.name,
        mime_type: file.type,
        size: file.size,
      })

      const { id, url } = response.data.data

      await axios.put(url, file, {
        headers: { 'Content-Type': file.type },
      })

      return id
    },

    async submit() {
      this.submitting = true
      let payload = {
        category_id: this.form.category_id,
        title: this.form.title,
        content: this.form.content
          ? this.form.content === '<p></p>'
            ? null
            : this.form.content
          : null,
        is_free: this.form.is_free,
      }

      try {
        if (this.form.video instanceof File) {
          payload.video_thumb_nail_id = await this.uploadFile(this.form.video)
        }

        if (this.form.thumbnail instanceof File) {
          payload.thumb_nail_id = await this.uploadFile(this.form.thumbnail)
        }

        const { data } = await Api.put(
          `/admin/programs/${this.programId}`,
          payload
        )

        this.$store.commit('program/setSelectedProgram', {
          loading: false,
          data: data.data,
        })

        this.resetForm()

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Program successfully updated.',
        })
      } catch (e) {
        if (e.response?.data?.errors) {
          this.form.$setErrors(e.response?.data?.errors)
        }
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.thumbnail-uploader {
  height: 100%;
  border-radius: 8px;
}

.thumbnail-uploader {
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #e6e6e9;
  justify-content: space-between;
}
</style>
